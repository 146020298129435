import React from "react";
import Container from "@material-ui/core/Container";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import Twitter from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f5f3f3",
    marginTop: "30px",
    padding: "20px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <p>661-840-1582</p>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <p>@2022 by Kesar </p>
          </Grid>
          <a href="https://www.facebook.com/KesarBakersfield" target="blank">
            <FacebookIcon />
          </a>
          <a href="https://twitter.com/KesarFoodTruck" target="blank">
            <Twitter />
          </a>
          <a href=" https://www.instagram.com/kesar_bakersfield" target="blank">
            <InstagramIcon />
          </a>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
