export const DELHI_STREAT_FOOD = [
  {
    item: "Samosa(2 Pieces)",
    price: "$2.00",
  },
  {
    item: "Samosa Chaat",
    price: "$7.00",
  },
  {
    item: "Gol Gappe",
    price: "$7.00",
  },
  {
    item: "Dahi Puri",
    price: "$7.00",
  },
  {
    item: "Bhalla Papri Chaat",
    price: "$8.00",
  },
  {
    item: "Aloo Tikki Chaat",
    price: "$8.00",
  },
  {
    item: "Dhahi Bhalla",
    price: "$7.00",
  },
  {
    item: "Amratsari Chole Kulche",
    price: "$9.00",
  },
];
export const HYD_SPECIALS = [
  {
    item: "Chicken Dum Biriyani",
    price: "$12.00",
  },
  {
    item: "Goat Ghee Roast Biriyani",
    price: "$14.00",
  },
  {
    item: "Paneer Biriyani",
    price: "$12.00",
  },
];
export const INDO_CHINESE = [
  {
    item: "Chilli Paneer",
    price: "$111.50",
  },
  {
    item: "Chilli Chicken",
    price: "$12.00",
  },
  {
    item: "Chicken 65",
    price: "$12.00",
  },
  {
    item: "Gobhi Manchurian",
    price: "$11.00",
  },
  {
    item: "Vegitable Manchurian",
    price: "$11.50",
  },
  {
    item: "Chicken Manchurian",
    price: "$12.00",
  },
  {
    item: "Chicken Lollipop",
    price: "$13.00",
  },
];
export const DOSA_HOUSE = [
  {
    item: "Plain Dosa",
    price: "$8.00",
  },
  {
    item: "Butter Dosa",
    price: "$9.00",
  },
  {
    item: "Masala Dosa",
    price: "$11.00",
  },
  {
    item: "Onion Masala Dosa",
    price: "$11.50",
  },
  {
    item: "Mysoor Masala Dosa",
    price: "$12.50",
  },
  {
    item: "Spring Dosa",
    price: "$12.00",
  },
  {
    item: "Paneer Dosa",
    price: "$13.00",
  },
  {
    item: "Cheese Dosa",
    price: "$9.50",
  },
  {
    item: "Podi Masala Dosa",
    price: "$12.50",
  },
  {
    item: "Kesar Special Dosa",
    price: "$15.00",
  },
];

export const CURRIES = [
  {
    item: "Paneer Tikka Masala",
    price: "$12.00",
  },
  {
    item: "Paneer Makhani",
    price: "$12.00",
  },
  {
    item: "Amritsari Chole",
    price: "$11.00",
  },
  {
    item: "Chicken Tikka Masala",
    price: "$13.00",
  },
  {
    item: "Butter Chicken",
    price: "$13.00",
  },
  {
    item: "Goat Curry",
    price: "$14.00",
  },
];
export const BREADS = [
  {
    item: "Butter Naan",
    price: "$2.00",
  },
  {
    item: "Garlic Naan",
    price: "$3.00",
  },
  {
    item: "Amritsari Kulcha",
    price: "$4.00",
  },
];
export const NOODLES = [
  {
    item: "Veg Hakka Noodles",
    price: "$11.00",
  },
  {
    item: "Paneer Hakka Noodles",
    price: "$11.50",
  },
  {
    item: "Egg Hakka Noodles",
    price: "$11.50",
  },
  {
    item: "Chicken Hakka Noodles",
    price: "$12.50",
  },
];
export const FRIED_RICE = [
  {
    item: "Veg Fried Rice",
    price: "$10.00",
  },
  {
    item: "Paneer Fried Rice",
    price: "$11.00",
  },
  {
    item: "Egg Fried Rice",
    price: "$10.50",
  },
  {
    item: "Chicken Fried Rice",
    price: "$12.00",
  },
];
