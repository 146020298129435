import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IMAGE from "../../assets/banner-bg.jpeg";
import LOGO from "../../assets/logo1.png";
import CHAAT from "../../assets/samosa_chaat.jpg";
import BIRYANI from "../../assets/biryani.jpg";
import CURRY from "../../assets/curry.jpg";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import Collections from "./Collections";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import OURSTORY from "../../assets/our_story.jpg";
import NewMenu from "../../components/NewMenu";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundImage: "url(" + IMAGE + ")",
    background: "brown",
    height: "100%",
    fontSize: "50px",
    /* display: block; */
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  logo: {
    paddingTop: "50px",
    textAlign: "center",
  },
  mainDiv: {
    height: "450px",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  curBtn: {
    background: "#a6332a",
    color: "#ffffff",
    border: "2px solid #ffffff",
    "&:hover": {
      background: "#a6332a !important",
    },
  },
  media: {
    height: 140,
  },
  marginTop: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  ourStory: {
    background: "#000000",
    opacity: "0.9",
    height: "auto",
  },
  discover: {
    color: "#f57347",
    fontWeight: "bold",
  },
  title: {
    color: "#ffffff",
    fontSize: "40px",
    margin: "0 0 20px 0",
  },
  desc1: {
    color: "#ffffff",
    textAlign: "center",
  },
  desc2: {
    color: "#ffffff",
    textAlign: "center",
  },
  newMenu: {
    background: "#f5f3f3",
    height: "auto",
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const navigates = useNavigate();
  const orderNow = () => {
    navigates("/order-now");
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.mainContainer}></div>
      </div>
      <Grid container className={classes.ourStory}>
        <Container maxWidth="md" className={classes.marginTop}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <img src={OURSTORY} alt="logo" width="100%" />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <h3 className={classes.discover}>DISCOVER</h3>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <p className={classes.title}>Our Story</p>
                <b className={classes.desc1}>
                  We Have the Glory Begining, Thank's to our Staff & Customers.
                </b>
                <p className={classes.desc2}>
                  At KESAR you will find an invigorating journey through your
                  senses providing an experience of authentic Indian cuisine.
                  Our state of art kitchen and chef's magical menu of authentic
                  International preparations creating blend of tradition and
                  best semblance of fine dining.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container className={classes.newMenu}>
        <NewMenu />
      </Grid>
    </>
  );
};

export default LandingPage;
