import { Drawer } from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "60%",
    },
    pd: {
      padding: "10px",
    },
    menuItem: {
      padding: "10px !important",
      borderBottom: "2px solid #f57347 ",
    },
    activeMenuItem: {
      color: "#f57347",
      fontWeight: "bold",
    },
  })
);
const SideMenu = (props) => {
  const classes = useStyles();

  const { openDrawer } = props;
  return (
    <Drawer
      anchor="left"
      open={openDrawer}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container spacing={2} className={classes.pd}>
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CloseIcon onClick={props.closeDrawer} />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.menuItem}
        >
          <label className={classes.activeMenuItem}>Home</label>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.menuItem}
        >
          <label>MENU</label>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.menuItem}
        >
          <label>TRUCK LOCATOR</label>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.menuItem}
        >
          <label>CONTACT</label>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideMenu;
