import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IMAGE from "../../assets/img1.jpg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(8),
    textAlign: "center",
    color: "#ffffff",
    background: "#372f3b",
  },
  panel: {
    background: "#dbc8be",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },

  img: {
    width: "100%",
    height: "331px",
    borderRadius: "4px",
  },
}));

const OrderPanel = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onClickOrderNow = () => {
    navigate("/order-now");
  };
  return (
    <div className={classes.root}>
      <Grid container className={classes.panel}>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Paper elevation={0} className={classes.paper}>
            <p>- NOW AVAILABLE -</p>
            <h1>KESAR</h1>
            <p>
              Celebrating a special occasion? Check out our new Chef's Table
              Trays, perfect for larger groups of 20 or more. We'll bring your
              mouthwatering favorites, you bring the appetites!
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClickOrderNow()}
            >
              ORDER NOW
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <img src={IMAGE} alt="img" className={classes.img} />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderPanel;
