import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IMAGE from "../../assets/img1.jpg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "134px 64px",
    textAlign: "center",
    borderRadius: "0",
    color: "#ffffff",
    background: "#000000",
  },

  mapGrid: {
    textAlign: "center",
  },
  panel: {
    background: "#dbc8be",
    padding: "60px",
    color: "#ffffff",
  },
  img: {
    width: "100%",
    height: "331px",
    borderRadius: "4px",
  },
}));
const MapPanel = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.panel}>
      <Grid item xs={6}>
        <Paper elevation={0} className={classes.paper}>
          <h1>Get In Touch</h1>
          <p>2161 Oak Tree Rd, Edison, NJ 08820, USA</p>
          <a href="">7981527414</a>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <img src={IMAGE} alt="img" height={400} width="100%" />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.mapGrid}>
          Map will come here
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MapPanel;
