import React from "react";
import Grid from "@material-ui/core/Grid";
import Carousel from "./Carousel";
import Footer from "./Footer";
import MapPanel from "./MapPanel";
import MenuPanel from "./MenuPanel";
import OrderPanel from "./OrderPanel";
import PicsPanel from "./PicsPanel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  pdZero: {
    padding: "0 !important",
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <OrderPanel />
        </Grid>
        <Grid item xs={12} className={classes.pdZero}>
          <MenuPanel />
        </Grid>
        <Grid item xs={12} className={classes.pdZero}>
          <MapPanel />
        </Grid>
        <Grid item xs={12} className={classes.pdZero}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
