import { AppBar, Container, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: "#000000",
      padding: "5px",
    },
    socialMedia: {
      color: "#f57347 !important",
    },
  })
);
const TopBar = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container minWidth="xl">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <LocationOnIcon />
            <label>5341 S Union Ave, Bakersfield. CA 93307</label>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <a
              href="https://www.facebook.com/KesarBakersfield"
              target="blank"
              className={classes.socialMedia}
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/KesarFoodTruck"
              target="blank"
              className={classes.socialMedia}
            >
              <TwitterIcon />
            </a>
            <a
              href=" https://www.instagram.com/kesar_bakersfield"
              target="blank"
              className={classes.socialMedia}
            >
              <InstagramIcon />
            </a>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default TopBar;
