import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DateChangeDialog from "./DateChangeDialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import IndianAppetaizers from "./IndianAppetaizers";
import TandooriAppetaizer from "./TandooriAppetaizer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import LOGO from "../../assets/logo.png";
import Container from "@material-ui/core/Container";
import Collections from "../landiingPage/Collections";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  dateGrid: {
    border: "2px solid #ffffff",
    padding: "20px",
    // background: "#290b0f",
  },
  changeLabel: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    background: "#ffffff",
    color: "#000000",
  },
}));

const theme = createTheme();
const OrderNow = () => {
  const [openDialog, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const [value, setValue] = React.useState(0);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const classes = useStyles();
  const onClickChangeDate = () => {};

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const navigates = useNavigate();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Collections />
      </Container>
    </div>
  );
};

export default OrderNow;
