import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IMAGE from "../../assets/img1.jpg";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "#372f3b",
    padding: "70px",
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  media: {
    height: 140,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
  header: {
    textAlign: "center",
    padding: "0px 300px",
    lineHeight: "1.5",
    [theme.breakpoints.down("md")]: {
      padding: "0px 20px",
    },
  },
  showBtns: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  showIndvBtns: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  mZero: {
    margin: 0,
  },
}));
const MenuPanel = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.panel}>
      <Grid item xs={12}>
        <div className={classes.header}>
          <h1>The Menu</h1>
          <p>
            The chef-driven menu highlights seasonal ingredients in simple,
            handcrafted dishes served straight from our kitchen. Add even more
            to your experience with a selection from our mouthwatering
            appetizers, and trust us—save room for dessert.
          </p>
          <p className={classes.showBtns}>
            <Button variant="contained" color="primary">
              ORDER NOW
            </Button>{" "}
            <Button variant="contained" color="primary">
              ORDER NOW
            </Button>{" "}
            <Button variant="contained" color="primary">
              ORDER NOW
            </Button>
          </p>
          <p className={classes.showIndvBtns}>
            <Button variant="contained" color="primary">
              ORDER NOW
            </Button>
          </p>
          <p className={classes.showIndvBtns}>
            <Button variant="contained" color="primary">
              ORDER NOW
            </Button>
          </p>
          <p className={classes.showIndvBtns}>
            <Button variant="contained" color="primary">
              ORDER NOW
            </Button>
          </p>
        </div>
      </Grid>
      <Grid item xs={12} container spacing={3} className={classes.mZero}>
        <Grid item xs={12} md={12} lg={4} xl={4}>
          <Card>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={IMAGE}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Pani Puri
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4} xl={4}>
          <Card>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={IMAGE}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Flavorful Rice
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4} xl={4}>
          <Card>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={IMAGE}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Lamb Chops
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenuPanel;
