import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import OrderNow from "./pages/orderNow/OrderNow";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

// import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { THEME_PROVIDER } from "./ThemeProvider";
import LandingPage from "./pages/landiingPage/LandingPage";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import TopBar from "./components/Topbar";
const useStyles = makeStyles((theme) => ({
  pdZero: {
    padding: 0,
  },
  root: {
    position: "relative",
  },
}));

const theme = createTheme({
  palette: THEME_PROVIDER,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "dashed" },
          style: {
            textTransform: "none",
            border: `2px dashed #f3f300`,
          },
        },
        {
          props: { variant: "dashed", color: "secondary" },
          style: {
            border: `4px dashed #f3f300`,
          },
        },
      ],
    },
  },
});

const App = () => {
  // const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    fetch("http://localhost:8080/hello")
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {});
  }, []);
  return (
    <MuiThemeProvider theme={theme} className={classes.root}>
      <React.Fragment>
        <Container maxWidth="xl" className={classes.pdZero}>
          <BrowserRouter>
            <TopBar />
            <NavBar />
            <Routes>
              <Route index element={<LandingPage />} />
              <Route path="" element={<LandingPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/order-now" element={<OrderNow />} />
              <Route path="/large-order" element={<OrderNow />} />
            </Routes>
          </BrowserRouter>
        </Container>
        <Footer />
      </React.Fragment>
    </MuiThemeProvider>
  );
};

export default App;
