import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import IMAGE from "../assets/no-image.png";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  countMain: {
    width: "80px",
    margin: "20px 0",
  },
  countInner: {
    width: "100%",
    display: "flex",
    border: "2px solid #ccc",
  },
  iconBtns: {
    padding: "0 !important",
    margin: "0 5px ",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundImage: "url(" + IMAGE + ")",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "190px",
    color: "#ffffff",
  },
  textField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, img, classes, onClose, ...other } = props;
  console.log("img", img);
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const OrderPopup = (props) => {
  const { open, data } = props;
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const setTotalPrice = () => {
    return count > 0 ? "$" + data.price * count : "";
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        img={data.img}
        title={data.name}
        id="customized-dialog-title"
        onClose={props.handleClose}
      ></DialogTitle>
      <DialogContent>
        <h3>{data.name}</h3>
        <Typography gutterBottom>{data.content}</Typography>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="outlined-multiline-static"
            label="Special Requests"
            multiline
            rows={3}
            variant="outlined"
          />
        </FormControl>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className={classes.countMain}>
            <div className={classes.countInner}>
              <div className={classes.minus}>
                <IconButton
                  aria-label="delete"
                  className={classes.iconBtns}
                  onClick={() => {
                    if (count > 0) setCount(count - 1);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
              <div className={classes.count}>{count}</div>
              <div className={classes.plus}>
                <IconButton
                  aria-label="delete"
                  className={classes.iconBtns}
                  onClick={() => {
                    setCount(count + 1);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary">
          Add to My Order {setTotalPrice()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderPopup;
