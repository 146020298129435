import SAMOSA_CHAAT from "../assets/samosa_chaat.jpg";
import DAHI_BALLA from "../assets/dahi_balla.jpeg";
import PAPRI_CHAAT from "../assets/papri_chaat.jpg";
import CHKN_DUM from "../assets/chicken_dum.jpg";
import MTN_GHEE from "../assets/mutton_ghee_roat_biriyani.jpg";
import CHKN_GHEE from "../assets/chicken_ghee_roat_biryani.jpeg";
import ROTI from "../assets/roti.jpeg";
import KULCHA from "../assets/kulcha.jpeg";
import TAVVA_PARATA from "../assets/plain_tavva_parata.jpg";

export const NormalOrder = [
  {
    title: "Chats from Delhi streets",
    landingTitle: "Chaats",
    items: [
      {
        name: "Samoosa Chat",
        price: "$7",
        img: SAMOSA_CHAAT,
        content:
          "Crunchy fried snack with chickpeas, potatoes, yogurt & spices",
      },
      {
        name: "Dahi Balla",
        price: "$7",
        img: DAHI_BALLA,
        content:
          "Crunchy fried snack with chickpeas, potatoes, yogurt & spices",
      },
      {
        name: "Papri Chaat",
        price: "$7",
        img: PAPRI_CHAAT,
      },
      {
        name: "Alo Tikki Chaat",
        price: "$7",
      },
      {
        name: "Chole Bhature",
        price: "$8",
      },
      {
        name: "Puri Chole",
        price: "$8",
      },
      {
        name: "Special basket chaat",
        price: "$8.5",
      },
      {
        name: "Amrutsati Chole Kulcha",
        price: "$8.5",
      },
    ],
  },
  {
    title: "Authentic Biryanis",
    landingTitle: "Biryanis",
    items: [
      {
        name: "Chicken Dum Biryani",
        img: CHKN_DUM,
        price: "$11",
      },
      {
        name: "Goat ghee roast biryani",
        price: "$13.50",
        img: MTN_GHEE,
      },
      {
        name: "Chicken Ghee Roast Biryani",
        price: "$12",
        img: CHKN_GHEE,
      },
      {
        name: "Paneer Biryani",
        price: "$10.50",
      },
      {
        name: "Veggie delight Biryani",
        price: "$10",
      },
    ],
  },
  {
    title: "Breads",
    landingTitle: "Breads",
    items: [
      {
        name: "Home Made Roti",
        price: "$1",
        img: ROTI,
      },
      {
        name: "Kulcha",
        price: "$2",
        img: KULCHA,
      },
      {
        name: "Plain Tavva paratha",
        price: "$2.50",
        img: TAVVA_PARATA,
      },
      {
        name: "Mixed Vegitable Paratha",
        price: "$4",
      },
      {
        name: "Bhatura",
        price: "$3",
      },
    ],
  },
];

export const PartyOrder = [
  {
    title: "Appetizers",
    landingTitle: "",
    items: [
      {
        name: "Vada Pav",
        largeTryPc: "20 PC",
        smallTryPc: "10 PC",
        sPrice: "$40",
        lPrice: "$75",
        content:
          "Crunchy fried snack with chickpeas, potatoes, yogurt & spices",
      },
      {
        name: "Samosa",
        largeTryPc: "40 PC",
        smallTryPc: "20 PC",
        sPrice: "$30",
        lPrice: "$55",
        content:
          "Crunchy fried snack with chickpeas, potatoes, yogurt & spices",
      },
      {
        name: "Cocktail Samosa",
        sPrice: "$35",
        lPrice: "$65",
      },
    ],
  },
  {
    title: "Non-Veg Appatizers",
    landingTitle: "",
    items: [
      {
        name: "Chicken 65",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "Chicken Fry",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "Amritsari fish",
        sPrice: "$65",
        lPrice: "$120",
      },
    ],
  },
  {
    title: "Veg curries",
    landingTitle: "Veg curries",
    items: [
      {
        name: "Chawli",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "Yellow Dal",
        sPrice: "$55",
        lPrice: "$100",
      },
      {
        name: "Chole",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "	Moong-Masar Dal	",
        sPrice: "$55",
        lPrice: "$100",
      },
      {
        name: "Aloo Gobhi",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "Aloo Baigan",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "	Paneer tikka masala",
        sPrice: "$65",
        lPrice: "$120",
      },
      {
        name: "Soy chaap",
        sPrice: "$65",
        lPrice: "$120",
      },
      {
        name: "	Mutter Paneer",
        sPrice: "$65",
        lPrice: "$120",
      },
    ],
  },
  {
    title: "	Non Veg Curries",
    landingTitle: "Non Veg Curries",
    items: [
      {
        name: "	Chicken curry	",
        sPrice: "$65",
        lPrice: "$120",
      },
      {
        name: "	Goat curry",
        sPrice: "$75",
        lPrice: "$140",
      },
      {
        name: "	Chicken tikka masala	",
        sPrice: "$70",
        lPrice: "$130",
      },
    ],
  },
  {
    title: "	Veg Biryanis",
    landingTitle: "Veg Biryanis",
    items: [
      {
        name: "	Veg Dum Biryani	",
        sPrice: "$55",
        lPrice: "$110",
      },
      {
        name: "	Paneer Biryani",
        sPrice: "$60",
        lPrice: "$115",
      },
    ],
  },
  {
    title: "Non	Veg Biryanis",
    landingTitle: "Non Veg Biryanis",
    items: [
      {
        name: "Chicken Dum Biryani",
        sPrice: "$60",
        lPrice: "$115",
      },
      {
        name: "	Chicken Fry pease biryani	",
        sPrice: "$65",
        lPrice: "$120",
      },
      {
        name: "Chicken boneless biryani	",
        sPrice: "$65",
        lPrice: "$120",
      },
      {
        name: "Goat ghee roast biryani",
        sPrice: "$70",
        lPrice: "$135",
      },
    ],
  },
  {
    title: "Chaats",
    landingTitle: "Chaats",
    items: [
      {
        name: "Papdi chaat",
        sPrice: "$45",
        lPrice: "$85",
      },
      {
        name: "Dahi bhalla",
        sPrice: "$50",
        lPrice: "$90",
      },

      {
        name: "Samosa chaat	",
        sPrice: "$50",
        lPrice: "$90",
      },
      {
        name: "aloo tikki chhaat	",
        sPrice: "$50",
        lPrice: "$90",
      },
      {
        name: "Chana Chaat	",
        sPrice: "$55",
        lPrice: "$100",
      },
    ],
  },
  {
    title: "Breads",
    landingTitle: "Breads",
    items: [
      {
        name: "Home made Roti	",
        tray: "50 PC",
        price: "$35",
      },
      {
        name: "Kulcha",
        tray: "50 PC",
        price: "$100",
      },
      {
        name: "Bhatura",
        tray: "50 PC",
        price: "$100",
      },
    ],
  },
  {
    title: "Desserts",
    landingTitle: "Desserts",
    items: [
      {
        name: "Gulab Jamun",
        sPrice: "$50",
        lPrice: "$90",
      },
      {
        name: "Rasmalai",
        sPrice: "$60",
        lPrice: "$110",
      },
      {
        name: "Rice kheer",
        sPrice: "$50",
        lPrice: "$90",
      },
    ],
  },
];
