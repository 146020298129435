import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Container } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import LOGO from "../assets/logo.png";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontWeight: "bold",
    },
    appBar: {
      backgroundColor: "#ffffff",
    },
    menuItem: {
      fontWeight: "bold",
      borderRadius: 0,
    },
  })
);

const NavBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setDrawer] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const navigates = useNavigate();
  return (
    <AppBar position="static" className={classes.appBar}>
      {matches ? (
        <Container minWidth="xl">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => {
                  navigates("/");
                }}
              >
                <img src={LOGO} alt="logo" width={70} />
              </IconButton>
              KESAR
            </Typography>
            <Button color="inherit" className={classes.menuItem}>
              HOME
            </Button>
            <Button color="inherit">MENU</Button>
            <Button color="inherit">TRUCK LOCATOR</Button>
            <Button color="inherit">CONTACT</Button>
          </Toolbar>
        </Container>
      ) : (
        <Container minWidth="xl">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon
                  onClick={() => {
                    setDrawer(!openDrawer);
                  }}
                />
              </IconButton>
              KESAR
            </Typography>
            <SideMenu
              openDrawer={openDrawer}
              closeDrawer={() => {
                setDrawer(false);
              }}
            />
          </Toolbar>
        </Container>
      )}
    </AppBar>
  );
};

export default NavBar;
