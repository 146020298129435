import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import NO_IMAGE from "../../assets/no-image.png";
import { NormalOrder, PartyOrder } from "../../shared/collectionMock";
import OrderPopup from "../../components/OrderPopup";
import LargeOrderPopUp from "../../components/LargeOrderPupUp";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardRoot: {
    maxWidth: 345,
    border: "2px solid #f5cf47",
  },
  cardContent: {
    fontWeight: "bold",
    color: "#f57347",
    background: "#f5cf47",
  },
  media: {
    height: 140,
    // background: "#a6332a",
  },
}));

const Collections = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState([]);
  const [openPopup, setPopup] = useState(false);
  const [openLargeOrderPopUp, setLargeOrderPopUp] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (location.pathname === "/order-now" || location.pathname === "/") {
      setMenuList(NormalOrder);
    } else {
      setMenuList(PartyOrder);
    }
  }, [location.pathname]);

  const onCickItem = (item) => {
    setSelectedItem(item);
    if (location.pathname === "/large-order") {
      setLargeOrderPopUp(true);
    } else {
      setPopup(true);
    }
  };

  const ItemList = (props) => {
    const { items } = props;
    let list = [];

    if (location.pathname === "/") {
      list = items.filter((obj, index) => {
        return index <= 2;
      });
    } else {
      list = [...items];
    }
    return (
      <>
        {list.map((itemObj, itemIndex) => {
          return (
            <Grid item xs={12} md={4} lg={4} xl={4} key={itemIndex}>
              <Card
                className={classes.cardRoot}
                onClick={() => onCickItem(itemObj)}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={NO_IMAGE}
                    title="Contemplative Reptile"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h3">
                      {itemObj.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </>
    );
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <h2>All Collections</h2>
        </Grid>
      </Grid>
      {menuList.map((obj, index) => {
        return (
          <Grid container spacing={3} key={index}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              {location.pathname === "/" ? (
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <h3>{obj.landingTitle}</h3>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <p
                      href=""
                      style={{ cursor: "pointer", margin: "0px" }}
                      onClick={() => {
                        navigate("/order-now");
                      }}
                    >
                      See All
                    </p>
                  </div>
                </div>
              ) : (
                <h3>{obj.title}</h3>
              )}
            </Grid>
            <ItemList items={obj.items} />
          </Grid>
        );
      })}
      {openPopup && (
        <OrderPopup
          open={openPopup}
          data={selectedItem}
          handleClose={() => {
            setPopup(false);
          }}
        />
      )}
      {openLargeOrderPopUp && (
        <LargeOrderPopUp
          open={openLargeOrderPopUp}
          data={selectedItem}
          handleClose={() => {
            setLargeOrderPopUp(false);
          }}
        />
      )}
    </div>
  );
};

export default Collections;
