import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import IMAGE from "../assets/samosa_chaat.jpg";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  countMain: {
    width: "80px",
    margin: "20px 0",
  },
  countInner: {
    width: "100%",
    display: "flex",
    border: "2px solid #ccc",
  },
  iconBtns: {
    padding: "0 !important",
    margin: "0 5px ",
  },
  radioAlg: {
    border: "1px solid",
    margin: "10px ",
  },
}));
const styles = (theme) => ({
  root: {
    height: "100%",
  },
  textField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const LargeOrderPopUp = (props) => {
  const { open, data } = props;
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const setTotalPrice = () => {
    return count > 0 ? "$" + data.price * count : "";
  };

  const setLabelAndPrice = (data, type) => {
    let optionTitle;
    if (type === "Small Tray") {
      optionTitle = data.smallTryPc
        ? type + " " + data.smallTryPc + " (" + data.sPrice + ")"
        : type + " (" + data.sPrice + ")";
    } else {
      optionTitle = data.largeTryPc
        ? type + " " + data.largeTryPc + " (" + data.lPrice + ")"
        : type + " (" + data.lPrice + ")";
    }

    return optionTitle;
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        <b>{data.name} </b>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom variant="h5">
          Tray Size
        </Typography>
        <div>
          <Typography variant="b" gutterBottom>
            Please select One Option
          </Typography>
        </div>
        <Typography variant="label" gutterBottom>
          {data.content}
        </Typography>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          // value={value}
          // onChange={handleChange}
        >
          <FormControlLabel
            value="ST"
            className={classes.radioAlg}
            control={<Radio />}
            label={setLabelAndPrice(data, "Small Tray")}
          />
          <FormControlLabel
            value="LT"
            className={classes.radioAlg}
            control={<Radio />}
            label={setLabelAndPrice(data, "Large Tray")}
          />
        </RadioGroup>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="outlined-multiline-static"
            label="Special Requests"
            multiline
            rows={3}
            variant="outlined"
          />
        </FormControl>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className={classes.countMain}>
            <div className={classes.countInner}>
              <div className={classes.minus}>
                <IconButton
                  aria-label="delete"
                  className={classes.iconBtns}
                  onClick={() => {
                    if (count > 0) setCount(count - 1);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
              <div className={classes.count}>{count}</div>
              <div className={classes.plus}>
                <IconButton
                  aria-label="delete"
                  className={classes.iconBtns}
                  onClick={() => {
                    setCount(count + 1);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary">
          Add to My Order {setTotalPrice()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LargeOrderPopUp;
