import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {
  BREADS,
  CURRIES,
  DELHI_STREAT_FOOD,
  DOSA_HOUSE,
  FRIED_RICE,
  HYD_SPECIALS,
  INDO_CHINESE,
  NOODLES,
} from "./menuList";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#f57347",
  },
  title: {
    margin: "0px 0 20px 0",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  itemCat: {
    textAlign: "center",
    color: "#f57347",
  },
}));

const NewMenu = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <h3 className={classes.heading}>OUR MENU</h3>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <h1 className={classes.title}>Choose The Best Of The Best</h1>
      </Grid>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} md={4}>
            <h3 className={classes.itemCat}>DELHI STREAT FOOD</h3>
            <MenuList>
              {DELHI_STREAT_FOOD.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="b" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
            <h3 className={classes.itemCat}>DOSA HOUSE</h3>
            <MenuList>
              {DOSA_HOUSE.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <h3 className={classes.itemCat}>HYDERABADI SPECIAL</h3>
            <MenuList>
              {HYD_SPECIALS.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
            <h3 className={classes.itemCat}>CURRIES</h3>
            <MenuList>
              {CURRIES.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
            <h3 className={classes.itemCat}>BREADS</h3>
            <MenuList>
              {BREADS.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <h3 className={classes.itemCat}>INDO CHINESE</h3>
            <MenuList>
              {INDO_CHINESE.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
            <h3 className={classes.itemCat}>HAKKA Noodles/Schzewan Noodles</h3>
            <MenuList>
              {NOODLES.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
            <h3 className={classes.itemCat}>
              Fried Rice/Chilli Garlic Fried Rice
            </h3>
            <MenuList>
              {FRIED_RICE.map((obj) => {
                return (
                  <MenuItem>
                    <ListItemText>{obj.item}</ListItemText>
                    <Typography variant="body2" color="#f57347">
                      {obj.price}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NewMenu;
