import React from "react";
import Grid from "@material-ui/core/Grid";
import IMAGE from "../../assets/img1.jpg";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "#000000",
    padding: "70px",
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  paper: {
    padding: "184px 64px",
    textAlign: "center",
    borderRadius: "0",
    color: "#ffffff",
    background: "#2a2b2c",
  },
  btmPaper: {
    padding: "64px",
    textAlign: "center",
    borderRadius: "0",
    background: "#000000",
    color: "#ffffff",
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.panel}>
      <Grid item xs={12} md={12} lg={6} xl={6}>
        <img src={IMAGE} alt="alt" height={500} width="100%" />
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={6}>
        <Paper elevation={0} className={classes.paper}>
          <h1>Get In Touch</h1>
          <p>2161 Oak Tree Rd, Edison, NJ 08820, USA</p>
          <a href="">7981527414</a>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Paper elevation={0} className={classes.btmPaper}>
          <a href="">7981527414</a>
          <p>2©2020 by Chef's Table |</p>
          <p>test@gmail.com</p>
          <p>icons</p>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Footer;
